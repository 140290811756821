'use strict';

angular.module('cpformplastApp.machines')
    .controller('machineCreationController', function($scope, appConfig, Modal, DataManager, $state, Notification) {

  $scope.machine = {
    type: 'manual'
  };
  $scope.types = [
    { value: 'Manuelle', rawValue: 'manual'},
    { value: 'Automatique', rawValue: 'automatic'}
  ];
  $scope.submitted = false;

  $scope.init = function() {
    if ($state.params.machineId) {
      DataManager.fetchMachine($state.params.machineId).then(machine => {
        $scope.machine._id = machine._id;
        $scope.machine.type = machine.type;
        $scope.machine.number = parseInt(machine.number);
      },function(error){
        console.log(error);
      });
    }
  };

  $scope.submitMachine = function(form, create) {

    $scope.submitted = true;
    if (!form.$valid) {
      return false;
    }

    var promise ;
    if (!$scope.machine._id) {
      promise = DataManager.createMachine($scope.machine);
    } else {
      promise = DataManager.updateMachine($scope.machine);
    }

    promise.then((data) => {

      Notification.success("La machine <strong>#" + $scope.machine.number + "</strong> fut enregistrée");

      if (create) {
        $state.go('machines/machine-creation', {'machineId' : ''}, {reload:true});
      } else {
        $state.go('machines/machine-creation', {'machineId' : data._id}, {reload:true});
      }
    })
    .catch(err => {
      if (err.data.err.code === 11000) {
        Notification.error('Une machine avec ce numero existe deja');
      } else {
        Notification.error('Un problème est survenu');
      }
    });

  };

  $scope.getTypeValue = function() {
    return $scope.types.find(type => type.rawValue === $scope.machine.type).value;
  };

  $scope.init();
});
